@import "./_variables";
@import "./_common";
@import "./horizontal-menu";
@import "./icon-menu";
@import "./_ecommerce";
@import "./_session";
@import "./email";
@import "./pricing";
@import "./ag-grid";
@import "./responsive";
@import "./teal-theme";
@import "./violet-theme";
@import "./dark-theme";
@import "./rtl-layout";

//footer container
.footer-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 72px;
  margin-bottom: 10px;
}

//flex container for default layout
.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

// changed close circle 
.close-button-large {
  border-radius: 50% !important;
  background-color: red !important;
  padding: 2px;
  position: absolute;
  top: 15px;
  width: 20px;
  height: 20px;
  padding: 2px;
  top: 15%;
  opacity: unset !important;
  z-index: 1111;
  margin: -15px 0 0px 50px;
}

// changed close icon to center in circle 
.close-button-large span {
  color: #fff;
}

.with-3d-effect {
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.with-3d-effect:hover {
  transform: translate(-3px, -3px);
}

.MuiBadge-anchorOriginTopRightRectangle {
  left: 0 !important;
  right: auto !important;
  transform: scale(1) translate(0%, -50%) !important;
}

